import React from 'react';
import './NewMain.css';
import George from './georgenew.png'
import Mike from './mike.png'
import Karan from './karan.png'
import John from './john.png'
import Denis from './denis.png'
import Robert from './robert.png'
import Jamal from './jamal.png'
import BillandFahad from './billandfahad.png'

class Team extends React.Component {
    
    render() {
        return (
          <div className="container marketing">
            <div className="text-center position-relative overflow-hidden pt-5 pb-3">
            <h2 className="featurette-heading fw-normal lh-1">Meet The
            <span className="text-body-secondary"> Team.</span>
            <p className="lead pt-4">Meet our dynamic team of innovators and collaborators.</p>
            </h2>
            </div>

            <hr className="featurette-divider mt-1" />

            <div className="row mb-5">
              <div className="col"> 
                <img src={BillandFahad} alt="Bill and Fahad" className="rounded-circle mx-auto mb-4" width="250" height="250" />
                <h2 className="fw-normal text-center">Fahad Alraddadi <span className="text-body-secondary">CEO</span> & Bill Monski <span className="text-body-secondary">COO</span></h2>
                <p className="text-justify">Fahad and Bill are both experienced electrical engineers and successful entrepreneurs. They met in 1999 as members of the MRI 
                coil design and development division of MEDRAD in Pittsburgh, PA. They started GaDova, the sister company to EDT in 2007. They are the co-inventors of the ECHO MRI
                 Coil Diagnostic System.</p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <img src={George} alt="George" className="rounded-circle mx-auto mb-4" width="140" height="140" />
                <h2 className="fw-normal">George Beluk <br/> <span className="text-body-secondary">CMO</span></h2>
                <p className="text-justify">George has been involved in the sales and operation of MRI units since the 1980s. 
                He has held various sales and management positions with Siemens, Philips, and Lunar Corporation. He has most recently
                been a partner in stand-alone MRI centers and veterinary MRI ventures.</p>
              </div>
              <div className="col-lg-4"> 
                <img src={Mike} alt="Mike" className="rounded-circle mx-auto mb-4" width="140" height="140" />
                <h2 className="fw-normal">Mike Voit <br/> <span className="text-body-secondary">Software Engineer</span></h2>
                <p className="text-justify">Mike is an integral part of the development and design of the cutting-edge algorithms 
                and machine learning systems in the ECHO products.</p>
              </div>
              <div className="col-lg-4"> 
                <img src={Karan} alt="Karan" className="rounded-circle mx-auto mb-4" width="140" height="140" />
                <h2 className="fw-normal">Karan Sachdev <br/> <span className="text-body-secondary">Design Engineer</span></h2>
                <p className="text-justify">Karan leads the mechanical design, development, prototyping and manufacturing of the 
                ECHO MRI Coil Monitoring System.</p>
              </div>
            </div>

            <hr className="featurette-divider" />

            <div className="text-center">
            <h2 className="featurette-heading fw-normal lh-1">Advisors
            <span className="text-body-secondary"></span>
            </h2>
            </div>

            <hr className="featurette-divider" />

            <div className="row">
              <div className="col-lg-4"> 
                <img src={John} alt="John" className="rounded-circle mx-auto mb-4" width="140" height="140" />
                <h2 className="fw-normal">John Friel</h2>
                <p className="text-justify">Mr. Friel is a well-known and respected leader in the medical electronics 
                industry. He is the past president of MEDRAD Inc. and currently sits as a member of several boards of directors.</p>
              </div>
              <div className="col-lg-4"> 
                <img src={Robert} alt="Robert" className="rounded-circle mx-auto mb-4" width="140" height="140" />
                <h2 className="fw-normal">Robert Lenkinski, PhD</h2>
                <p className="text-justify">Dr. Lenkinski is a prominent figure in MRI research. He has extensive 
                knowledge regarding the design and development of MRI coils. He is extensively published and holds several patents.</p>
              </div>
              <div className="col-lg-4">
                <img src={Denis} alt="Denis" className="rounded-circle mx-auto mb-4" width="140" height="140" />
                <h2 className="fw-normal">Denis Olson</h2>
                <p className="text-justify">Mr. Olson has an incredible depth of knowledge regarding MRI coil repair. He
                 served as the Director of MEDRADs Multi-Vendor Repair Service for 15 years.</p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 mx-auto"> 
                <img src={Jamal} alt="Jamal" className="rounded-circle mx-auto mb-4" width="140" height="140" />
                <h2 className="fw-normal">Jamal Tayab</h2>
                <p className="text-justify">Vice President of Finance at Aquatech International LLC in Canonsburg, PA. 
                Mr. Tayab expertise lies in financial management, strategic planning, and business development, cultivated
                 through a diverse career trajectory.</p>
              </div>
            </div>

          </div>
        )
    }
}



export default Team;