import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import NewMain from './NewMain';
import Privacy from './Privacy';
import Video from './Video';
import { Route, Routes } from "react-router-dom";
import Header from './Header';
import './NewMain.css';
import Contact from './Contact'
import Team from './Team'
import Footer from './Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <HashRouter>
        <Header />

        <Routes>
          <Route exact path="/" exact element={ <NewMain/> } />
          <Route path="/privacy" exact element={ <Privacy/> } />
          <Route path="/tour" exact element={ <Video/> } />
          <Route path="/contact" exact element={ <Contact/> } />
          <Route path="/team" exact element={ <Team/> } />
        </ Routes>

        <Footer />
      </ HashRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
