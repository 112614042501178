import React from 'react';
import {Nav} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import LogoSVG from './ECHO logo_svg-09-09.svg'
import './NewMain.css';

class Footer extends React.Component {
    
    render() {
        return (
          <div class="container pb-4">
            <hr className="featurette-divider" />
            <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
              <p class="col-md-4 mb-0 text-body-secondary">&copy; 2024 Echo Diagnostic Technologies</p>

              <a href="#" class="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <img src={LogoSVG} alt="Echo Logo" className="bi center-block" width="70" height="70" />
              </a>

              <ul class="nav col-md-4 justify-content-end">
                <li className="nav-item"><LinkContainer to="/privacy"><Nav.Link className="nav-link px-2 text-body-secondary">Privacy</Nav.Link></LinkContainer></li>
              </ul>
            </footer>
          </div>
        )
    }
}



export default Footer;