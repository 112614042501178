import React from 'react';
import {Nav} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import LogoSVG from './ECHO logo_svg-09-09.svg'
import './NewMain.css';

class Header extends React.Component {
    
    render() {
        return (
            <div>

              <script src="https://code.jquery.com/jquery-3.3.1.js" integrity="sha256-2Kok7MbOyxpgUVvAk/HJ2jigOSYS2auK4Pfzbm7uH60=" crossOrigin="anonymous"></script>

              <nav style={{backgroundColor: '#e5e5e5'}} className="navbar navbar-expand-md border-bottom" data-bs-theme="light">
                <div className="container">
                  <a className="navbar-brand d-md-none" href="#">
                  <img src={LogoSVG} alt="ECHO Logo" className="bi" width="70" height="70" />
                  </a>
                  <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="#offcanvas" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvas" aria-labelledby="#offcanvasLabel">
                    <div className="offcanvas-header">
                      <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                      <ul className="navbar-nav flex-grow-1 justify-content-between">
                        <li className="nav-item"><a className="nav-link" href="/">
                        <img src={LogoSVG} alt="ECHO Logo" className="bi" width="70" height="70" />
                        </a></li>
                        <li className="nav-item my-auto" data-bs-dismiss="offcanvas"><LinkContainer to="/"><Nav.Link>Product</Nav.Link></LinkContainer></li>
                        <li className="nav-item my-auto" data-bs-dismiss="offcanvas"><LinkContainer to="/team"><Nav.Link>About</Nav.Link></LinkContainer></li>
                        <li className="nav-item my-auto" data-bs-dismiss="offcanvas"><LinkContainer to="/tour"><Nav.Link>Tour</Nav.Link></LinkContainer></li>
                        <li className="nav-item my-auto" data-bs-dismiss="offcanvas"><LinkContainer to="/contact"><Nav.Link>Contact</Nav.Link></LinkContainer></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
        )
    }
}



export default Header;