import React from 'react';
import './NewMain.css';

class Video extends React.Component {
    
    render() {
        return (
          <div className="container marketing">

          <div className="text-center position-relative overflow-hidden pt-5 pb-3">
            <h2 className="featurette-heading fw-normal lh-1">Video Tour</h2>
            <p className="pt-4">See our technology in action</p>
          </div>

          <div className="row">

              <div className="col-8 text-center mx-auto my-auto videotext">
              
              <hr className="featurette-divider mt-1" />
              
                  <div className="col-12 text-left">
                    <p className="">Every day, thousands of MRI examinations are cancelled or postponed due to the failure
                    of an MRI coil.
                    </p>
                  </div>

                  <div className="col-12 text-center videotextbold">
                    <p className="headingtwo">A critical component of all MRI Systems
                    </p>
                  </div>
                  <div className="col-12 text-left">
                    <p className="">This leads to a disruption in patient care, especially for patients that are critically ill or require
                    <span className="headingone"> general anesthesia</span> to be scanned in an MRI.
                    </p>
                  </div>
                  <div className="col-12 text-center videotextbold">
                    <p className="headingtwo"><span className="headingone">All veterinary patients </span> and some pediatric patients 
                    </p>
                  </div>
                  <div className="col-12 text-left">
                    <p className="">ECHO Diagnostic Technologies has invented a way to determine if a coil is going to fail before it is needed.
                    </p>
                  </div>
                  <div className="col-12 text-center videotextbold pb-4">
                    <p className="headingtwo">Think of it as a <span className="headingone">"Check Engine"</span> light for your MRI
                    </p>
                  </div>

                <div class="ratio ratio-16x9">
                  <iframe title="ECHO Technologies Video Tour" class="mx-auto my-auto" src="https://drive.google.com/file/d/1JhghWv5d517LrPCV2vZzf1XPId-YmpWA/preview" allowFullScreen="true"></iframe>
                </div>
              </div>
          </div>

          </div>
        )
    }
}



export default Video;