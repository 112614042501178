import './NewMain.css';
import TableRender from './mockup.png'
import embedded from './thumbnail_Screenshot_1.png'
import handheld from './thumbnail_Screenshot_2.png'
import platform from './thumbnail_ECHO platform iso view 1.jpg'
import bigPlatform from './thumbnail_ECHO platform iso view 2.jpg'
import appPicture from './Screenshot_3.png'
import tableBottom from './tower.2.png'

function NewMain() {
  return (
    <div className="NewMain">

      <script src="https://code.jquery.com/jquery-3.3.1.js" integrity="sha256-2Kok7MbOyxpgUVvAk/HJ2jigOSYS2auK4Pfzbm7uH60=" crossOrigin="anonymous"></script>

      <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
        <symbol id="chevron-right" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
        </symbol>
      </svg>

      <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
        <div className="col-md-7 p-lg-5 mx-auto my-5">
        <h2 className="display-3 headingone">Quality Control for MRI Coils</h2>
          <h3 className="display-5 headingone">Designed for more efficient patient care</h3>
          <h3 className="text-muted mb-3 headingthree">Detect MRI coil failure with the <br/> <span className="headingone">ECHO™</span> Coil Monitoring System</h3>
          <div className="d-flex gap-3 justify-content-center lead fw-normal">
            <a className="icon-link" href="/#/tour">
              Take A Tour
              <svg className="bi"><use xlinkHref="#chevron-right"/></svg>
            </a>
          </div>
        </div>
        <div className="product-device d-none d-xl-block"><img src={TableRender} alt="Table Render" className=""/></div>
        <div className="product-device product-device-2 d-none d-xl-block"><img src={TableRender} alt="Table Render" className=""/></div>
      </div>

    <div className="container marketing">

      <div className="row pt-4">
        <div className="col-lg-4 mx-auto my-auto">
          {/*<svg className="bd-placeholder-img rounded-circle mx-auto" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg>*/}
          <img src={platform} alt="Platform Render" className="bd-placeholder-img rounded-circle mx-auto mb-4" width="200" height="200" />
          <h2 className="fw-normal">Tower</h2>
          <p>Designed for all of the MRI installed base across the country.</p>
          {/* <p><a className="btn btn-secondary" href="#">View details &raquo;</a></p> */}
        </div>
        <div className="col-lg-4 mx-auto my-auto">
          <img src={embedded} alt="Embedded Render" className="bd-placeholder-img rounded-circle mx-auto mb-4" width="200" height="200" />
          <h2 className="fw-normal">Embedded</h2>
          <p>System for coils that will be designed and manufactured in the future.</p>
          {/* <p><a className="btn btn-secondary" href="#">View details &raquo;</a></p> */}
        </div>
        <div className="col-lg-4 mx-auto my-auto">
          <img src={handheld} alt="Handheld Render" className="bd-placeholder-img rounded-circle mx-auto mb-4" width="200" height="200" />
          <h2 className="fw-normal">Handheld</h2>
          <p>Designed for "bench" and field service engineers.</p>
          {/* <p><a className="btn btn-secondary" href="#">View details &raquo;</a></p> */}
        </div>
      </div>


      {/*<!-- START THE FEATURETTES -->*/}

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1 pb-4">Every day there are over 440 various coil malfunctions.* <span className="text-body-secondary">Detect a failure before it happens.</span></h2>
          <p className="lead">The ECHO™ MRI Coil Monitoring System works by utilizing artificial intelligence to monitor MRI coils when they are not in 
          use. Our patented system transmits a passive RF signal to the many subsystems of the MRI coil. These signals are reflected as an 
          "ECHO" in milliseconds and are instantly compared to the coil baseline specifications that are stored in the cloud.</p>
            <figcaption class="blockquote-footer">
              * <cite title="Source Title">Source: Grand View Research, San Francisco, CA</cite>
            </figcaption>
        </div>
        <div className="col-md-5">
          {/*<svg className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"/><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg>*/}
          <img src={tableBottom} alt="Table Bottom" className="featurette-image img-fluid mx-auto" width="500" height="500" />
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-7 order-md-2">
          <h2 className="featurette-heading fw-normal lh-1 pb-4">Designed and engineered for patient care. {/*<span className="text-body-secondary">See for yourself.</span>*/}</h2>
          <p className="lead">By allowing the MRI provider to detect coil failure early, this allows for a more efficient method of operations and 
          scheduling.</p>
        </div>
        <div className="col-md-5 order-md-1">
          <img src={bigPlatform} alt="Platform Render" className="featurette-image img-fluid mx-auto" width="500" height="500" />
        </div>
      </div>

      <hr className="featurette-divider" />

      <div className="row featurette">
        <div className="col-md-7">
          <h2 className="featurette-heading fw-normal lh-1 pb-4">24/7 real time dashboard and mobile app.{/*<span className="text-body-secondary">Checkmate.</span>*/}</h2>
          <p className="lead">The information collected by the ECHO™ system is always available via a "dashboard" and mobile app to the clinical manager
           and the service manager charged with the operation and maintenance of the MRI. If an anomaly is detected, management personnel are 
           immediately notified of a suspected problem via email and text before the MRI coil is placed in service.</p>
        </div>
        <div className="col-md-5">
          <img src={appPicture} alt="Mobile App" className="featurette-image img-fluid mx-auto" width="500" height="500" />
        </div>
      </div>

      {/*<!-- /END THE FEATURETTES -->*/}

    </div>
    </div>
  );
}

export default NewMain;
